/* topbar */

.topBar {
    background-color: #1E5EFF;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.topBar>.topBarContact,
.topBar>.topBarSocial {
    display: flex;
    flex-direction: row;
}

.topBar>.topBarSocial>a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.topBar>.topBarContact>p {
    margin-bottom: 0;
    color: #fff;
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.topBar>.topBarContact>p>svg {
    margin-right: 5px;
}

.topBar>.topBarSocial {
    width: 90px;
    justify-content: space-around;
}

/* navBar */


.navBar {
    width: 100%;
    height: 130px;
    padding: 30px 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #00001B;
}

.navBarButton {
    height: 65px;
    width: 210px;
    background-color: #1E5EFF;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-fixed {
    position: fixed;
    top: 0;
    width: 100%;
    /* Assurez-vous que la barre de navigation occupe toute la largeur */
    z-index: 1000;
    /* Assurez-vous que la barre de navigation reste au-dessus des autres éléments */
}
#btnNavWeb {
    display: flex;
}

#btnNavMobile {
    display: none;
}

/* header */

header {
    height: calc(100vh - 130px);
    background-image: url("../../Images/headerBg.png");
    background-size: cover;
}

.headercontent {
    width: 45%;
    height: 100%;
    background: rgb(0, 0, 27);
    background: linear-gradient(180deg, rgba(0, 0, 27, 0.83) 16%, rgba(7, 22, 79, 1) 100%);
    padding: 150px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px;

}

.headercontentWelcome {
    color: #fff;
    margin: 0;
    font-size: 22px;
    font-weight: 600;
}

.headercontentWelcome svg {
    margin-right: 5px;
}

.headercontentSlogant {
    color: #fff;
    margin: 0;
    font-size: 45px;
    font-weight: bold;
}

.headercontentSlogant span {
    color: #1E5EFF;
}

.headercontentText {
    color: #7E84A3;
    font-size: 14px;
    text-align: justify;
}

.headercontentButton {
    width: 150px;
    height: 50px;
    border-radius: 3px;
    background-color: #1E5EFF;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* ascension */

.ascensionContainer {
    display: flex;
}

.ascensionDescreptionContainer {
    width: 60%;
    padding: 50px 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title-container p {

    color: #00001B;
    font-size: 22px;
    font-weight: 600;
}

.title-container p svg {
    margin-right: 5px;
}

.title-container h3 {
    color: #00001B;
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 20px;
}

.title-container>.text-descreption {
    text-align: center;
    color: #7E84A3;
    font-size: 20px;
    font-weight: 400;
}

.title-container>.text-descreption-start {
    text-align: start;
    color: #7E84A3;
    font-size: 20px;
    font-weight: 400;
}

.ascensionContainer .text-container {
    color: #333752;
    font-size: 14px;
    text-align: justify;
}

.ascensionImageContainer {
    padding: 0 100px;
    background-color: #D7DBEC;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ascensionImageContainer img {
    width: 100%;
}


/* service  */
.service-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.serviceCardContainer {
    background-color: #F4F4F4;
    display: flex;
    flex-direction: column;
    padding: 15px;
    height: 100%;
}

.serviceCardContainer>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.serviceCardContainer h4 {
    color: #37393F;
    font-size: 22px;
    flex: 2;
    margin: 0;
}

.serviceCardContainer>div>div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

/* .serviceCardContainer>div>div {
    width: 50px;
    height: 50px;
} */

.serviceCardContainer>div>div>svg {
    width: 50px;
}

.serviceCardContainer>p {
    font-size: 14px;
    margin: 0;
}

/* whyUs */

.whyUsDescreptionContainer {
    width: 60%;
    padding: 0 175px 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.whyUsImageContainer>div>h3 {
    color: #00001B;
    font-size: 45px;
    font-weight: bold;
}

.whyUsImageContainer>div>p {
    color: #333752;
    font-size: 25px;
}

.whyUsDescreptionContainer ul {
    list-style: none;
    padding: 0;
}

.whyUsDescreptionContainer ul li {
    display: flex;
    flex-direction: row;
}

.whyUsDescreptionContainer ul li h4 {
    color: #00001B;
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.whyUsDescreptionContainer ul li p {
    color: #333752;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
}

.whyUsDescreptionContainer ul li svg {
    margin-right: 10px;
    width: 22px;
}

.whyUsContainer {
    display: flex;
}

.whyUsImageContainer {
    padding: 30px;
    background-color: #D7DBEC;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

}


/* devis */
.devis-form {
    width: 100%;
}

.devis-form>div {
    width: 80%;
    display: flex;
    margin: auto;
}

.devis-form>div>div {
    flex: 1;
    padding: 0 20px;
}

.devis-form>div>div>input {
    width: 100%;
    background-color: #F5F6FA;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    height: 50px;
    font-size: 16px;
    padding: 5px;
}

.devis-form>div>div>.error {
    color: red;
    font-size: 14px;
}

.devis-form>div>div>input::placeholder {
    color: #7E84A3;
}

.devis-form #besoin {
    width: 100%;
    background-color: #F5F6FA;
    border: 1px solid #DDDDDD;
    border-radius: 3px;
    height: 100px;
    font-size: 16px;
    padding: 5px;
}

.devis-form #besoin::placeholder {
    color: #7E84A3;
}

.submit-devis {
    width: 100%;
    height: 50px;
    background-color: #1E5EFF;
    color: #fff;
    border: none;
    border-radius: 3px;
}

/* contact */
.contact-image {
    display: flex;
    align-items: center;
}

.contact-image img {
    width: 100%;

}

.contact-info>div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.contact-info>div>div {
    margin-left: 10px;
}

.contact-info>div p {
    margin: 0;
    font-size: 18px;
    color: #7E84A3;
}

.contact-info>div h4 {
    margin: 0;
    font-size: 24px;
    color: #00001B;
    font-weight: 500;
    max-width: 400px;
}

footer {
    width: 100%;
    height: 220px;
    padding: 0 100px;
    background-color: #00001B;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.separation-footer {
    width: 100%;
    height: 2px;
    background-color: #1E5EFF;
}

.foter-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.foter-content>p {
    margin: 0;
    color: #fff;
    font-size: 18px;
}

.foter-content>p>span {
    color: #1E5EFF;

}

.footer-icons {
    display: flex;
    flex-direction: row;
    align-content: center;
}

.footer-icons>p {
    margin: 0;
    color: #fff;
    font-size: 18px;
    margin-right: 10px;
}

.footerSocial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80px;
}

.footerSocial>a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-containerForHeader {
    display: none;
}

.navButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

/* Styles for screens between 768px and 1023px */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .navBar {
        padding: 30px;
    }

    header {
        height: 500px;
    }

    .headercontentSlogant {
        font-size: 36px;
    }

    .headercontent {
        padding: 20px;
    }

    /* ascension */

    .ascensionDescreptionContainer {
        width: 60%;
        padding: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .ascensionImageContainer {
        padding-left: 20px;
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }

    .ascensionImageContainer img {
        width: 200%;
    }

    /* WhyUs */
    .whyUsDescreptionContainer {
        width: 100%;
        padding: 0 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .whyUsContainer {
        display: flex;
        flex-direction: column-reverse;
    }

    .whyUsImageContainer {
        padding: 30px;
        background-color: #D7DBEC;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

    }

    .whyUsImageContainer>div {
        margin-top: 10px;
    }

    .whyUsImageContainer>div>h3 {
        font-size: 35px;
    }

    .whyUsImageContainer>div>p {
        font-size: 20px;
    }

    .devis-form>div {
        flex-direction: column;
    }

    .devis-form>div>div:first-child {
        margin-bottom: 1rem;
    }

    .contact-image {
        display: none;
    }

    #contact .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #contact .title-container .text-descreption-start {
        text-align: center;
        max-width: 400px;
    }

    .contact-info {
        padding: 25px;
    }

    .contact-info>div h4 {
        max-width: 500px;
    }
}

/* Styles for screens smaller than 768px */
@media screen and (max-width: 767px) {
    .navBar {
        width: 100%;
        height: 100px;
        padding: 20px;
    }
    .navBarButton {
        height: 50px;
        width: 50px;
    }
    .navBar>svg {
       width: 150px;
    }
    .topBar {
        display: none;
    }

    .headercontent {
        width: 100%;
        height: auto;
        padding: 30px 20px;
    }

    .headercontentSlogant {
        font-size: 36px;
    }

    header {
        height: auto;
        background-size: cover;

    }

    .title-container h3 {
        font-size: 35px;
    }

    .image-containerForHeader {
        display: block;
    }

    .image-containerForHeader img {
        width: 100%;
    }

    .ascensionDescreptionContainer {
        width: 100%;
        padding: 10px;
    }

    .ascensionImageContainer {
        display: none;
    }

    /* WhyUs */
    .whyUsDescreptionContainer {
        width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .whyUsContainer {
        display: flex;
        flex-direction: column-reverse;
    }

    .whyUsImageContainer {
        padding: 30px;
        background-color: #D7DBEC;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

    }

    .whyUsImageContainer>div {
        margin-top: 10px;
    }

    .whyUsImageContainer>div>h3 {
        font-size: 28px;
    }

    .whyUsImageContainer>div>p {
        font-size: 18px;
    }

    .devis-form>div {
        flex-direction: column;
    }

    .devis-form>div>div:first-child {
        margin-bottom: 1rem;
    }

    .devis-form>div {
        width: 100%;
    }

    .contact-image {
        display: none;
    }

    #contact .title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #contact .title-container .text-descreption-start {
        text-align: center;
        max-width: 400px;
    }

    .contact-info {
        padding: 25px;
    }

    .contact-info>div svg {
        display: none;
    }

    .contact-info>div h4 {
        font-size: 20px;
    }

    footer {
        width: 100%;
        height: 150px;
        padding: 20px;
    }

    .foter-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #btnNavWeb {
        display: none;
    }

    #btnNavMobile {
        display: flex;
    }
}
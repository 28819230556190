.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal.open {
  display: flex;
}



.modal-content {
  background-color: #F6F6F6;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  min-height: 200px;
  min-width: 400px;
  width: auto;
  height: auto;
  /* align-items: center; */
  justify-content: space-between;
}

.modal-content .cancel-button {

  width: 120px;
  height: 40px;
  border: none;
  color: #F0142F;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
}

.modal-content .action-button {

  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #F0142F;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.titleModal {
  font-size: 20px;
  font-weight: 700;
  color: var(--dark-blue-color);
}

.messageModal {
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-blue-color);
}

.modal-content-animation {
  background-color: #F6F6F6;
  padding: 20px;
  border-radius: 22px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  min-height: 250px;
  min-width: 514px;
  align-items: center;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
}



/* personnel modal */
.modal-content-personnel {
  background-color: #F6F6F6;
  padding: 20px 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  min-height: 200px;
  width: 400px;
  max-height: 90vh;
  overflow-y: scroll;
}

.modal-content-personnel::-webkit-scrollbar {
  width: 8px;
}

.modal-content-personnel::-webkit-scrollbar-thumb {
  background: #F0F0F1;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.modal-content-personnel::-webkit-scrollbar-thumb:hover {
  background: #F0F0F1;
}

.modal-content-personnel::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.container-personnel-details {}

.header-personnel-details {
  display: flex;
  justify-content: space-between;
}

.body-personnel-details {
  display: flex;
  flex-direction: row;

}

.left-body-personnel-details {
  flex: 1;
}

.right-body-personnel-details {
  flex: 1;
}

.footer-personnel-details {}

.footer-personnel-details-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 5px;
}

.detail-personnel-title {
  color: var(--dark-blue-color);
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.detail-personnel-label {
  color: var(--grey-color-600);
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.detail-personnel-text {
  color: var(--grey-color-400);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.detail-personnel-export-btn {
  width: 100px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--grey-color-300);
  color: var(--light-blue-color);
  background-color: transparent;
}

.separation-line {
  height: 2px;
  width: 100%;
  background-color: #E6E9F4;
  margin: 5px 0;
}

.vertical-line-separation {
  width: 2px;
  height: 30px;
  background-color: #13152380;
}


.inpt-container {
  display: flex;
  flex-direction: column;

}

.select-personalise {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}



.modal-content-conge-details {
  justify-content: normal !important;
  height: 400px !important;
  width: 600px !important;
}

.modal-content-conge-details>.table-personnel-container {
  height: calc(400px - 80px);
  overflow-y: scroll;
}

.modal-content-conge-details>.table-personnel-container::-webkit-scrollbar {
  width: 8px;
}

.modal-content-conge-details>.table-personnel-container::-webkit-scrollbar-thumb {
  background: #F0F0F1;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.modal-content-conge-details>.table-personnel-container::-webkit-scrollbar-thumb:hover {
  background: #F0F0F1;
}

.modal-content-conge-details>.table-personnel-container::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.client-detail-modal-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.client-detail-modal-content>p {
  color: var(--dark-blue-color);
  font-size: 16px;
  font-weight: bold;
}

.AcceptedConge {
  width: 90px;
  height: 25px;
  background-color: #C4F8E2;
  color: #06A561;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

.RefusedConge {
  width: 90px;
  height: 25px;
  background-color: #FCD5D9;
  color: #F0142F;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}


/* modal-content-fichePaie */
.modalPaie {
  justify-content: right !important;
}
.modal-content-fichePaie {
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: space-between; */
}
.confirm-paie {
  background-color: #D7DBEC;
  width: 200px;
  height: 60px;
  border: none;
  border-radius: 5px;
  color: #00001B;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-left: 30px;
  margin-right: 10px;
}

.modal-content-fichePaie>div>.fichePaiContainer {
  height: 100%;
}
.todoContainer {
    width: 100%;
    height: auto;
    max-height: 300px;
    background-color: #E6E9F4;
    padding: 20px;
    overflow-y: scroll;
}

.todoContainer::-webkit-scrollbar {
    width: 8px;
}

.todoContainer::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
}

.todoContainer::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
}

.todoContainer::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
}

.todoContainer h4 {
    color: #1E5EFF;
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 20px;
}

.todoContent {
    border-bottom: 1px solid #A1A7C4;
    margin-bottom: 10px;
}

.todoContent>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.todoTitle {
    color: #00001B;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.todoAction{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 50px;
}
.todoAction button{
    border: none;
    background-color: transparent;
 }

.todoText {
    color: #5A607F;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.AddTodo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 50px 50px 10px 50px;
}
.todoInput {
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    width: 400px;
}

.AddTodoAction {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 10px;
}
.AddTodoAction .action {
    flex: 1;
    padding: 6px;
    border-radius: 5px;
}
.AddTodoAction .confirm {
    background-color: #1E5EFF;
    color:#fff;
    border: 1px solid #fff;
}
.AddTodoAction .cancel {
    background-color: #fff;
    color:#1E5EFF;
    border: 1px solid #1E5EFF;
}
#addtodo {
    border: none;
    background-color: transparent;
}

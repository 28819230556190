@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
.selected-row {
  background-color: #d3d3d3; /* Couleur gris clair pour la sélection */
}


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;

}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background: #F0F0F1;
  border: 1px solid rgba(10, 13, 28, 0.35);
}
*::-webkit-scrollbar-thumb:hover {
  background: #F0F0F1;
}

*::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
}


body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #FAFBF9;
  width: 100%;
  box-sizing: border-box;
}

.MuiPopover-paper.css-kc02vp-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background-color: #fff;
  max-height: 300px;
}
.Toastify__progress-bar--bg.Toastify__progress-bar--default {
  background: var(--grey-color-100);
}
.Toastify__progress-bar.Toastify__progress-bar--default {
  background: var(--dark-blue-color);
}
/* .MuiDateCalendar-root.css-1q04gal-MuiDateCalendar-root {
  background-color: #fff;
  color:var(--grey-color-500)
}
.MuiButtonBase-root.MuiPickersDay-root.css-1acgcob-MuiButtonBase-root-MuiPickersDay-root{
  color:var(--grey-color-500)
} */

.toTop {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--light-blue-color);
  border: none;
  position: fixed;
  bottom: 10px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toTop>img {
  width: 20px;
}

:root {
  --light-blue-color: #1E5EFF;
  --dark-blue-color: #131523;
  --grey-color-700: #333752;
  --grey-color-600: #5A607F;
  --grey-color-500: #7E84A3;
  --grey-color-400: #A1A7C4;
  --grey-color-300: #D7DBEC;
  --grey-color-200: #E6E9F4;
  --grey-color-100: #F5F6FA;
  --sky-blue-color: #4FC0FF;
  --yellow-color: #FBDD4E;
  --green-color: #06A561;
  --red-color: #F12B43
}

.hauteur-100 {
  height: 100%;
}
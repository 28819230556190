.sideBar {
  width: 250px;
  background: #00001B;
  height: calc(100vh - 60px);
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  padding: 0 15px;
}

.sideBar::-webkit-scrollbar {
  width: 8px;
}

.sideBar::-webkit-scrollbar-thumb {
  background: #F0F0F1;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.sideBar::-webkit-scrollbar-thumb:hover {
  background: #F0F0F1;
}

.sideBar::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
}
.sideBar>.top-sideBar {
  padding: 30px 0;
}

.sideBar>.top-sideBar>ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sideBar .side-bar-main-links ul li span {
  text-align: center;
  color: var(--grey-color-400);
  font-size: 13px;
}
.sideBar>.top-sideBar>ul>li {
  height: 40px;
  width: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 25px;
}

.sideBar>.top-sideBar>ul>li {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  width: 100%;
}

.sideBar>.top-sideBar>ul>li>.clicked {
  color: #fff;
  text-decoration: none;
  display: block;
  font-size: 13px;
  font-weight: bold;

}

.sideBar>.top-sideBar>ul>li:hover {
  background: linear-gradient(90deg, var(--light-blue-color), #1e5eff00);
}

.sideBar>.top-sideBar>ul>li.active {
  background: linear-gradient(90deg, var(--light-blue-color), #1e5eff00);
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-weight: bold;
}

.logout-button {
  background-color: #1E5EFF;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 40px;
  color: #fff;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 30px;
}

.sideBar .side-bar-main-links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sideBar .side-bar-main-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sideBar .side-bar-main-links ul>li {
  height: 55px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  font-size: 13px;
  font-weight: normal;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 3px;
}


.sideBar .side-bar-main-links ul>li>a {
  color: #fff;
  text-decoration: none;
  display: block;
  font-size: 13px;
  font-weight: normal;
  margin-right: 10px;
}

.sideBar .side-bar-main-links ul>li:hover {
  background: linear-gradient(90deg, var(--light-blue-color), #1e5eff00);
}

.sideBar .side-bar-main-links ul>li.active {
  background: linear-gradient(90deg, var(--light-blue-color), #1e5eff00);
  font-size: 13px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.sideBar .side-bar-main-links ul>li.active>span {
  color: #fff;
}

.sideBar .side-bar-main-links ul>li.notActive {
  font-size: 13px;
}

.sideBar-icons-conatainer {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}


.mini-side-bar-logo {
  display: none;
}



.category-title {
  margin: 10px 0;
}

.category-title>p {
  color: var(--grey-color-400);
  font-size: 12px;
}

.sideBar-client {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

@media (max-width: 1030px) {
  .sideBar .side-bar-main-links ul li span {
    /* Masquer les éléments du menu en ajustant la propriété display */
    display: none;
  }

  .sideBar {
    width: 85px;
  }

  .side-bar-logo {
    display: none;
  }

  .mini-side-bar-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .mini-side-bar-logo img {
    width: 75px;
  }

  .sideBar .side-bar-main-links ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 40px;
  }

  .sideBar .side-bar-main-links ul li svg {
    margin: 0;
    width: 30px;
    height: 30px;
  }
}
.navBar-container {
    width: 100%;
    height: 60px;
    background-color: #00001B;
}
.header-container {
    padding: 0 30px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-container>img {
    width: 100%;
}

.header-right-container {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}


/* admin header */
.header-gest-pages {
    background-color: #fff;
    height: 80px;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
}

.header-gest-pages-left{
    height: 100%;
    flex: 1;
}
.header-gest-pages-right {
    height: 100%;
    flex: 2;
}
.header-gest-pages-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.header-gest-pages-left>.text-and-back>h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--grey-color-700);
    /* margin-left: 20px; */
    margin-bottom: 0;
    margin-top: 0;
}
.header-gest-pages-left>.text-and-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}
.header-gest-pages-left>.text-and-back>a {
    cursor: pointer;
    text-decoration: none;
}

.text-and-back >button {
    background: none; 
  border: none; 
  margin-right: 10px;
  
}




.header-gest-pages-right .triAndSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-gest-pages-right .sort-select {
    background: #fff;
    margin-left: 10px;
    padding: 8px;
    width: 215px;
    border: none;
    border-radius: 5px;
    height: 40px;
    border: var(--grey-color-300) 1px solid;
    font-size: 16px;
    margin-right: 20px;
}

.header-gest-pages-right .sort-select>option:first-child {
    color: var(--grey-color-400);
}

.header-gest-pages-right .search-container-personnel {
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E") no-repeat 13px;
    border: none;
    border-radius: 5px;
    padding: 9px 4px 9px 40px;
    width: 250px;
    height: 40px;
    border: var(--grey-color-300) 1px solid;
    font-size: 16px;
}

.header-gest-pages-right .search-container-personnel::placeholder {
    color: var(--grey-color-400);
}

.header-gest-pages-right .DarkBtn {
    background-color: var(--light-blue-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 8px;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: auto;
}
.header-gest-pages-right .DarkBtn svg {
    margin-right: 5px;
}

.table-personnel {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;

}

.table-personnel th,
.table-personnel td {
    padding: 10px;
    text-align: left;

}

.table-personnel th {
    background-color: #fff;
    color: var(--grey-color-600);
    font-size: 14px;
    font-weight: 400;
    border-bottom: 3px solid var(--grey-color-300);
    text-align: center;

}

.table-personnel td {
    color: #0F1637;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
}

.table-personnel td>span {
    color: var(--grey-color-600);
}

.table-personnel tr {
    border-bottom: 2px solid var(--grey-color-300);

}

.table-personnel .selected_tr,
.table-personnel tr:hover {
    background-color: var(--grey-color-200);
}

.table-personnel tr:last-child {
    border-bottom: none;
}

.table-personnel td:last-child {
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.table-personnel td .actionsBtn {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid var(--grey-color-300);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 8px;
}

.table-personnel td .detailsBtn {
    background-color: transparent;
    color: var(--light-blue-color);
    border: none;
    margin: 0 8px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 16px;
    font-weight: 600;
}

.personnel-table-container {
    background-color: var(--grey-color-100);
    width: 95%;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);


}

.personnel-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.personnel-header-container .SearchPersonnelContainer {
    flex: 1;
}

.personnel-header-container .triAndSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.personnel-header-container .triAndSearch>div>button {
    border: none;
    background-color: transparent;
    /* color: var(--light-blue-color); */
    color: var(--grey-color-600);
    font-size: 16px;
    font-weight: 400;
}

.personnel-header-container .triAndSearch>div {
    width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.personnel-header-container .triAndSearch>div>button.active {
    border: none;
    background-color: transparent;
    color: var(--light-blue-color);
    font-size: 16px;
    font-weight: 400;
    border-bottom: 2px solid var(--light-blue-color);
}

.personnel-header-container .actionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* .personnel-table-content { */
    /* height: 450px;
    overflow-y: hidden; */
/* } */

/* .personnel-table-content::-webkit-scrollbar {
    width: 8px;
}

.personnel-table-content::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
}

.personnel-table-content::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
}

.personnel-table-content::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
} */

.sort-select {
    background: #fff;
    margin-left: 10px;
    padding: 8px;
    width: 215px;
    border: none;
    border-radius: 5px;
    height: 40px;
    border: var(--grey-color-300) 1px solid;
    font-size: 16px;
    margin-right: 20px;
}

.sort-select>option:first-child {
    color: var(--grey-color-400);
}

.search-container-personnel {
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' className='bi bi-search'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E") no-repeat 13px;
    border: none;
    border-radius: 5px;
    padding: 9px 4px 9px 40px;
    width: 300px;
    height: 40px;
    border: var(--grey-color-300) 1px solid;
    font-size: 16px;
}

.search-container-personnel::placeholder {
    color: var(--grey-color-400);
}

.LightBtn {
    background-color: #fff;
    color: var(--light-blue-color);
    border: 1px solid var(--light-blue-color);
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 8px;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.DarkBtn {
    background-color: var(--light-blue-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 8px;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

.LightBtn svg,
.DarkBtn svg {
    margin-right: 5px;
}

.pagination-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.pagination-container button {
    height: 36px;
    width: 36px;
    color: var(--grey-color-600);
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.prev-next-pagination-btn {
    background-color: #fff;
    border-radius: 5px;
    border: none;
    margin: 0 5px;
}

.btn-paginationNumber {
    background-color: transparent;
    border: none;
}

.pagination-container button.active {
    color: var(--light-blue-color);
    background-color: #ECF2FF;
    border: none;
    border-radius: 5px;
}


.filter-month {
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter-month>button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid var(--grey-color-300);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 8px;
}

.filter-month>p {
    margin: 0;
    color: var(--light-blue-color);
    font-size: 16px;
    font-weight: 400;
}
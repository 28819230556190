.notifContainerDash {
    width: 100%;
    height: auto;
    max-height: 400px;
    background-color: #fff;
    border: 1px solid #A1A7C4;
    padding: 20px;
    overflow-y: scroll;
}

.notifContainerDash::-webkit-scrollbar {
    width: 8px;
}

.notifContainerDash::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
}

.notifContainerDash::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
}

.notifContainerDash::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
}
.notifContHeader {
    margin-bottom: 20px;
    padding: 5px 10px;
}
.notifContainerDash .notifContHeader h4 {
    color: #1E5EFF;
    font-size: 16px;
    font-weight: bold;
   margin-bottom: 0;
}

.NotifContent {
    border-bottom: 1px solid #A1A7C4;
}
.NotifContent:hover {
    background-color: #E5E5E5;
    cursor: pointer;
}

.NotifContent>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.notifTitle {
    color: #00001B;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.notifTime {
    color: #979797;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.notifText {
    color: #5A607F;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.notifCard {
    width: 100%;
    height: 80px;
    background-color: #F5F6FA;
    margin-bottom: 20px;
    padding: 10px;
    margin: auto;
}

.notifCard>div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.notifCard>div>p {
    color: #00001B;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.notifCard>p {
    color: #5A607F;
    font-size: 16px;
    margin: 0;
}
.formulaire-main-container {
    width: 100%;
    padding: 0 30px;
}
.formulaire-conatiner {
    height: calc(100vh - 160px);
    overflow-y: scroll;
    background-color: var(--grey-color-100);
    padding: 30px 15px;
    margin: 0 auto;
    border-radius: 5px;
}

.formulaire-conatiner::-webkit-scrollbar {
    width: 8px;
}

.formulaire-conatiner::-webkit-scrollbar-thumb {
    background: #F0F0F1;
    border: 1px solid rgba(10, 13, 28, 0.35);
}

.formulaire-conatiner::-webkit-scrollbar-thumb:hover {
    background: #F0F0F1;
}

.formulaire-conatiner::-webkit-scrollbar-track {
    background: #fff;
    border: 1px solid rgba(10, 13, 28, 0.35);
}

.form-label {
    color: var(--grey-color-600);
    font-size: 14px;
    font-weight: 400;
}

.form-check-input:checked {
    background-color: var(--light-blue-color);
    border-color: var(--light-blue-color);
}

.form-control {
    background-color: #fff;
    font-size: 14px;
    border-color: var(--grey-color-200);
    color: var(--grey-color-600);
}

.form-select {
    font-size: 14px;
}

.small_inpt_form {
    width: 47%;

}

.form-control:focus,
.form-check-input:focus {
    border-color: #1e5effa0;
    box-shadow: 0 0 0 0.25rem #1e5eff4e
}


.radio-form-container .form-check .form-label {
    margin-right: 10px;
}

.radio-form-container .form-check .form-check-label {
    margin-right: 10px;
    font-size: 14px;
    color: #131523;
    font-weight: 500;
}

.form-title {
    font-size: 16px;
    font-weight: bold;
    color: #131523;
}

.errorText {
    color: red;
    font-size: 13px;
}


.addImageFormContainer {
    min-height: 150px;
    height: auto;
    background-color: #fff;
    border: 1px dashed var(--grey-color-400);
    padding: 20px 0;
}

.btn-add-image-form {
    width: 230px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--grey-color-400);
    font-size: 16px;
    font-weight: 400;
    color: var(--light-blue-color);
    background-color: transparent;
}

.image-label {
    color: var(--grey-color-600);
    font-size: 14px;
    font-weight: 400;
}

.small-img-cont-form {
    border-radius: 5px;
    height: auto;
    margin-top: 10px;
    width: 60px;
}
.small-img-cont-form >img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.culumnFormContainer>span {
    color: red;
}
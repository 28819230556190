.ListSitesContainer {
  border: 2px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;
}
.header h4 {
  font-size: 1rem;
  font-weight: bold;
  color: #1e5eff;
}
#site-state {
  border: 1px solid;
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
  color: #fff;
}
.date-picker button {
  background-color: #1e5eff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-weight: 500;
  font-size: .9rem;
}
#not-found-text {
  font-size: 1.2rem;
  font-weight: bold;
  color: #000;
  margin-top: 20px;
  text-align: center;
}
.site-row {
  cursor: pointer;
}
.AdminListContainer {
    background-color: #F5F6FA;
    padding-top: 15px;
}
.AdminListContainerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 15px;
}

.AdminListContainerBody {
    max-height: 300px;
    overflow-y: scroll;
}
.AdminListContainerHeader button{
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.DetailsAdminContainer  h4 , .AdminListContainer h4 {
    font-size: 18px;
    font-weight: bold;
    color: #1E5EFF;
    margin-bottom: 0;

}

.DetailsAdminContainer .mb-3 h5, .AdminListContainer .mb-3 h5 {
    color:#5A607F;
    font-size: 16px;
    font-weight: bold
}
.AdminCardContainer {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 10px 20px;
}

.AdminCardContainer:hover {
    background-color: #D7DBEC;
}

.AdminCardContainer.active {
    background-color: #D7DBEC;

}

.AdminCardContainer>.AdminCardContainerINfo {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    margin-left: 10px;
}

.AdminCardContainer h6 {
    font-size: 14px;
    font-weight: bold;
    color: #00001B;
    margin: 0;
}

.AdminCardContainer p {
    color: #7E84A3;
    font-size: 14px;
    margin: 0;
}



.DetailsAdminContainer {
    background-color: #F5F6FA;
    padding: 20px
}

.DetailsAdminHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.DetailsAdminHeader h4 {
    font-size: 18px;
    font-weight: bold;
    color: #1E5EFF;
}

.DetailsAdminHeader button {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 5px;
    background-color: #1E5EFF;
}

.DetailsAdminContent {}

.DetailsAdminDescreption {
    display: flex;
    flex-direction: row;
}

.DetailsAdminContent h3 {
    font-size: 24px;
    color: #00001B;
    font-weight: 600;
}

.detailsAdmin-label {
    color: #5A607F;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.detailsAdmin-text {
    color: #A1A7C4;
    font-size: 15px;
    font-weight: 400;
    margin-left: 5px;
    margin-bottom: 0;
}

.DetailsAdminHeaderBtns {
    width: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.DetailsAdminFonction {

}
/* .login-box {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 70vh;
    min-width: 600px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  }
   */
.LoginPagecontainer>.row {
  height: 100vh;
}

.login-left {
  background: linear-gradient(170deg, rgba(0, 0, 27, 1) 3%, rgba(7, 22, 79, 1) 100%);
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.login-left h2 {
  color: #fff;
  font-size: 64px;
}
.login-left img {
  width: 300px;
}
.login-right {
  display: flex;
    align-items: center;
    justify-content: center;
}

.loginbody {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  background-color: #F5F6FA;
}

.label-login {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;

}

.input-field-login {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #F3EFF5;

}

.button-login {
  background-color: var(--light-blue-color);
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  font-weight: 600;
  border-radius: 5px;
}

.button-login:hover {
  opacity: 0.8;
}

.login-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.login-password-link {
  font-size: 15px;
  color: #A3A6B2;
  margin-top: 20px;
  text-align: center;
}

.form-containerlogin {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* .login-body {
    width: 50%;
    height: 100%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
  }
   */


.decoration-bar {
  background-color: var(--primary-color);
  height: 100vh;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.decoration-bar>img {
  width: 200px;
}

.errorText {
  color: red;
  font-size: 13px;
}

.form-group {
  margin-bottom: 10px;
}

.password-input-container {
  position: relative;
}

.password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #A3A6B2;
  display: flex;
  align-items: center;
}

.password-toggle-icon img {
  width: 20px;
  height: 20px;
  color: red;
}

.password-toggle-icon:hover {
  color: #000;
}
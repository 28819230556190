.fichePaiContainer {
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fichePaiEntete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiEntete-clientDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fichePaiEntete-client-logo {}

.fichePaiEntete-client-Info {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.fichePaiEntete-client-Info>h5 {
    font-size: 11px;
    font-weight: bold;
    color: #00001B;
    margin: 0;
}

.fichePaiEntete-client-Info>p {
    font-size: 10px;
    color: var(--grey-color-500);
    font-weight: 400;
    margin: 0;
}

.fichePaiEntete-date {}

.fichePaiEntete-date>h4 {
    font-size: 20px;
    font-weight: bolder;
    color: #00001B;
    margin: 0;
}

.fichePaiEntete-date>p {
    font-size: 10px;
    color: var(--grey-color-500);
    font-weight: 400;
    margin: 0;
}


.fichePaiPersonnelInfo {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.fichePaiPersonnelInfo h4 {
    font-size: 20px;
    font-weight: bolder;
    color: #00001B;
    margin: 0;
}

.fichePaiPersonnelInfo h5 {

    font-size: 14px;
    font-weight: bold;
    color: var(--grey-color-600);
    margin: 0;
}

.fichePaiTable {

    display: flex;
    flex-direction: row;

}

.fichePaiTableLeft {
    border: 1px solid var(--grey-color-300);
    flex: 1;
    padding: 5px 2px;
}

.fichePaiTableRight {
    border: 1px solid var(--grey-color-300);
    border-left: 0.5px !important;
    flex: 2;
    padding: 6px 3px;
}

.fichePaiTableLeft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiTableLeft>.fichePaiTableLabel {
    margin-right: 3px;
}

.fichePaiTableRight>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiTableLabel {
    font-size: 12px;
    font-weight: 600;
    color: var(--grey-color-600);
    margin: 0;
}
.fichePaiTableLabel >span {
    font-size: 8px;
}
.fichePaiTableText {
    font-size: 12px;
    font-weight: 500;
    color: var(--grey-color-500);
    margin: 0;
}

.fichePaiTableContainer {
    margin-top: 20px;
}

.fichePaiTableContainer table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
}

.fichePaiTableContainer table tr,
.fichePaiTableContainer table td {
    border: 1px solid #D7DBEC;
}

.fichePaiTableContainer table tr>th {
    font-size: 10px;
    font-weight: 400;
    color: #00001B;
    margin: 0;
    text-align: center;
    background-color: #D7DBEC;
}

.fichePaiTableContainer table tr>td {
    font-size: 10px;
    font-weight: 500;
    color: #00001B;
    margin: 0;
    padding: 2px;
}

.fichePaiTableContainer table tr>td.numberPaiTable {
    font-size: 8px;
    font-weight: 500;
    color: #00001B;
    margin: 0;
    text-align: center;
}

.fichePaiTimeWork {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
}

.fichePaiTimeWorkLeft {
    width: 48%;
    border: 1px solid #1E5EFF;
    border-radius: 5px;
    padding: 10px;
}

.fichePaiTimeWorkLeft h3 {
    font-size: 12px;
    font-weight: bold;
    color: #1E5EFF;
}

.fichePaiTimeWorkLeft div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiTimeWorkLeft p {
    font-size: 8px;
    font-weight: bold;
    color: #00001B;
    margin: 0;
}

.fichePaiTimeWorkRight {
    width: 48%;
    border: 1px solid #1E5EFF;
    border-radius: 5px;
    padding: 10px;
}

.fichePaiTimeWorkRight div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiTimeWorkRight h3 {
    font-size: 12px;
    font-weight: bold;
    color: #1E5EFF;
}

.fichePaiTimeWorkRight p {
    font-size: 8px;
    font-weight: bold;
    color: #00001B;
    margin: 0;
}

.fichePaiComment {
    width: 100%;
    border: 1px solid #1E5EFF;
    border-radius: 5px;
    padding: 10px;
    margin-top: 10px;
}

.fichePaiComment h3 {
    font-size: 12px;
    font-weight: bold;
    color: #1E5EFF;
}

.fichePaiComment p {
    font-size: 8px;
    font-weight: 500;
    color: #5A607F;
    margin: 0;
}


/* Add fiche paie */

.fichePaiTableContainerForAdd table {
    width: 100%;
    /* border-collapse: collapse; */
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
    border: none;
}

.fichePaiTableContainerForAdd table tbody td:first-child {
    border-left: none;
}

.fichePaiTableContainerForAdd table tbody td:last-child {
    border-right: none;
}

/* .fichePaiTableContainerForAdd table tbody tr  */
.fichePaiTableContainerForAdd table thead tr>th {
    border: none;
}

.fichePaiTableContainerForAdd table tbody td {
    border: 1px solid #E6E9F4;
}

.fichePaiTableContainerForAdd table tr>th {
    font-size: 14px;
    font-weight: 400;
    color: #5A607F;
    margin: 0;
    text-align: center;
    border-top: none;
    padding: 10px 0;
    /* border-bottom: 2px solid #E6E9F4; */
}

.fichePaiTableContainerForAdd table tr>td {
    font-size: 14px;
    font-weight: 400;
    color: #00001B;
    margin: 0;
    padding: 2px;
    text-align: center;
    height: 30px;
}

.fichePaiTableContainerForAdd table tr>td.numberPaiTableForAdd {
    font-size: 14px;
    font-weight: 400;
    color: #00001B;
    margin: 0;
    width: 70px;
}

.fichePaiTableContainerForAdd table tr>td.numberPaiTableForAdd input {
    width: 100%;
    height: 100%;
    border: none;
    padding-left: 3px;
}

.fichePaiTableContainerForAdd table tr>td.numberPaiTableForAdd input:focus-visible {
    outline: none
}

.fichePaiTableContainerForAdd table tr>td.comment-td {
    text-align: start;
}

.fichePaiTableContainerForAdd table tr>td.comment-td input {
    width: 100%;
    height: 100%;
    border: none;
}

.fichePaiTableContainerForAdd table tr>td.comment-td input:focus-visible {
    outline: none
}

.fichePaiTimeWorkForAdd {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-flow: row wrap;
    margin-top: 10px;
}

.fichePaiTimeWorkLeftForAdd {
    width: 30%;
    border: 1px solid #1E5EFF;
    border-radius: 5px;
    padding: 10px;
}

.fichePaiTimeWorkLeftForAdd h3 {
    font-size: 16px;
    font-weight: bold;
    color: #1E5EFF;
}

.fichePaiTimeWorkLeftForAdd div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiTimeWorkLeftForAdd p:first-child {
    font-size: 14px;
    font-weight: 400;
    color: #5A607F;
    margin: 0;
}

.fichePaiTimeWorkLeftForAdd p {
    font-size: 14px;
    font-weight: 400;
    color: #00001B;
    margin: 0;
}

.fichePaiTimeWorkRightForAdd {
    width: 30%;
    border: 1px solid #1E5EFF;
    border-radius: 5px;
    padding: 10px;
}

.fichePaiTimeWorkRightForAdd div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.fichePaiTimeWorkRightForAdd h3 {
    font-size: 16px;
    font-weight: bold;
    color: #1E5EFF;
}

.fichePaiTimeWorkRightForAdd p {
    font-size: 14px;
    font-weight: 400;
    color: #00001B;
    margin: 0;
}

.fichePaiTimeWorkRightForAdd p:first-child {
    font-size: 14px;
    font-weight: 400;
    color: #5A607F;
    margin: 0;
}

.fichePaiCommentForAdd {
    width: 30%;
    border: 1px solid #1E5EFF;
    border-radius: 5px;
    padding: 10px;
}

.fichePaiCommentForAdd h3 {
    font-size: 16px;
    font-weight: bold;
    color: #1E5EFF;
}

.fichePaiCommentForAdd p {
    font-size: 14px;
    font-weight: 400;
    color: #5A607F;
    margin: 0;
}


.conatinerPaieEmp {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.formulaire-paie-employe {
    width: 33%;
}

.information-paie-employe {
    width: 63%;
    background-color: #E6E9F4;
    padding: 10px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
}

.information-paie-employe>div {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: space-around;
}
.information-paie-employe>div>div {
    display: flex;
    flex-direction: row;
    
}
.formulaire-paie-employe>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.formulaire-paie-employe>div:first-child {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.formulaire-paie-employe>div:first-child>div {
    width: 100%;
}
.formulaire-paie-employe>div:first-child>p {
    margin-bottom: 10px;
}
.formulaire-paie-employe>div> input {
    width: 70px;
    padding: 0 3px;
    border: none;
    border-radius: 5px;
    height: 35px;
}
.formulaire-paie-employe>div>p {
    margin: 0;
    width: 50%;
    font-size: 14px;
    color: #5A607F;
}
.information-paie-employe>div>div>p {
    font-size: 14px;
    color: #5A607F;
    margin: 0;
}


.information-paie-employe>div>div>p:first-child {
margin-right: 5px;
}
.cardClientContainer {
    width: 350px;
    height: 180px;
    border-radius: 5px;
    background-color: var(--grey-color-300);
    /* background-image: url("../../Images/logo.svg"); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 15px;
}

.cardClientMain {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.cardClientMain h4 {
    font-size: 16px;
    font-weight: 700;
    color: var(--dark-blue-color);
}

.cardClientBtnsConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 130px;
}

.cardClientBtnsConatiner .actionsBtn {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid var(--grey-color-300);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* .cardClientBtnsConatiner .actionsBtn:first-child {
    margin-right: 8px;
} */




/* card client details */
.cardClientDetailsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: var(--grey-color-100);
    border: 2px solid var(--grey-color-300);
    border-radius: 7px;
    padding: 20px;
    height: 300px;
    margin: 20px;
}

.ClientDetailsLeftSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 300px;
    border-right: 2px solid #979797;
}

.ClientDetailsLeftSection h3 {
    color: var(--grey-color-700);
    font-size: 20px;
    font-weight: 700;
}

.logo-container-client {
    width: 250px;
    height: 130px;
    background-color:  var( --grey-color-300);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
}
.logo-container-client img{
    height: 70px;
    width: auto;
}
.ClientDetailsRightSection {
    width: calc(100% - 300px);
    padding: 0 15px;
}

.ClientDetailsLabel {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--grey-color-600);

}

.ClientDetailsText {
 margin: 0;
 font-size: 14px;
 font-weight: 400;
 color: var(--grey-color-400);
}
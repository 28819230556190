.activeClientContainer {
  width: 100%;
  height: auto;
  max-height: 400px;
  background-color: #fff;
  border: 1px solid #a1a7c4;
  padding: 20px;
  overflow-y: scroll;
}

.activeClientContainer::-webkit-scrollbar {
  width: 8px;
}

.activeClientContainer::-webkit-scrollbar-thumb {
  background: #f0f0f1;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.activeClientContainer::-webkit-scrollbar-thumb:hover {
  background: #f0f0f1;
}

.activeClientContainer::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
}
.activeClientContHeader {
  margin-bottom: 20px;
  padding: 5px 10px;
}
.activeClientContainer .activeClientContHeader h4 {
  color: #1e5eff;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.ActiveClientCard {
  width: 100%;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #d8dbec;
  margin-bottom: 10px;
}

.clientName {
  color: #000;
  font-weight: bold;
  font-size: 20px;
}

.statItem {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.statItemHeader {
  color: #a1a7c4;
  font-weight: bold;
  font-size: 14px;
}
.statItemText {
  color: #a1a7c4;
  font-weight: bold;
  font-size: 16px;
}

.layout {
    display: flex;
  }
  
.content {
    flex: 1;
  }

.content {
  height: calc(100vh - 60px);
  overflow-y: scroll;
}
.content::-webkit-scrollbar {
  width: 8px;
}

.content::-webkit-scrollbar-thumb {
  background: #F0F0F1;
  border: 1px solid rgba(10, 13, 28, 0.35);
}

.content::-webkit-scrollbar-thumb:hover {
  background: #F0F0F1;
}

.content::-webkit-scrollbar-track {
  background: #fff;
  border: 1px solid rgba(10, 13, 28, 0.35);
}
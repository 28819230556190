.AproposContainer {
    max-height: 300px;
    overflow: auto;
    background-color: #F5F6FA;
    border: 1px solid #E6E9F4;
    border-radius: 5px;
    padding: 15px;
}

.AproposHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}

.AproposHeader h4 {
    font-size: 18px;
    font-weight: bold;
    color: #1E5EFF;
    margin-bottom: 0;
}

.AproposHeader button {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 5px;
    background-color: #1E5EFF;
}

.ApropsLabel {
    color: #5A607F;
    font-size: 14px;
    margin: 0;
}

.ApropsText {
    color: #A1A7C4;
    font-size: 16px;
    margin: 0;
}

.bannerContainer {
    background: rgb(0,0,27);
    background: linear-gradient(145deg, rgba(0,0,27,1) 0%, rgba(13,41,126,1) 100%);
    height: 250px;
    border-radius: 15px;
    margin: 20px 0;
    position: relative;
    padding: 25px 40px;
}
.bgForBanner {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.2;
    overflow: hidden;
    width: 100%;
}

.user-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.title {
    color: #5A607F;
    font-size: 14px;
}
.item {
    color: #A1A7C4;
    font-size: 14px;
}
